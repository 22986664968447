import _GradientButton2 from "./GradientButton";
var exports = {};
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _GradientButton = _interopRequireDefault(_GradientButton2);

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

var _default = _GradientButton.default;
exports.default = _default;
export default exports;
export const __esModule = exports.__esModule;