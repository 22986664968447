import _react2 from "react";
import _propTypes2 from "prop-types";
import _styledComponents2 from "styled-components";
import _styledSystem2 from "styled-system";
import _styledTools2 from "styled-tools";
import _utils2 from "./utils";
var exports = {};
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _react = _interopRequireDefault(_react2);

var _propTypes = _interopRequireDefault(_propTypes2);

var _styledComponents = _interopRequireWildcard(_styledComponents2);

var _styledSystem = _styledSystem2;
var _styledTools = _styledTools2;
var _utils = _utils2;

function _interopRequireWildcard(obj) {
  if (obj && obj.__esModule) {
    return obj;
  } else {
    var newObj = {};

    if (obj != null) {
      for (var key in obj) {
        if (Object.prototype.hasOwnProperty.call(obj, key)) {
          var desc = Object.defineProperty && Object.getOwnPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : {};

          if (desc.get || desc.set) {
            Object.defineProperty(newObj, key, desc);
          } else {
            newObj[key] = obj[key];
          }
        }
      }
    }

    newObj.default = obj;
    return newObj;
  }
}

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

function _extends() {
  _extends = Object.assign || function (target) {
    for (var i = 1; i < arguments.length; i++) {
      var source = arguments[i];

      for (var key in source) {
        if (Object.prototype.hasOwnProperty.call(source, key)) {
          target[key] = source[key];
        }
      }
    }

    return target;
  };

  return _extends.apply(this, arguments);
}

var REACT_ELEMENT_TYPE;

function _jsx(type, props, key, children) {
  if (!REACT_ELEMENT_TYPE) {
    REACT_ELEMENT_TYPE = typeof Symbol === "function" && Symbol.for && Symbol.for("react.element") || 60103;
  }

  var defaultProps = type && type.defaultProps;
  var childrenLength = arguments.length - 3;

  if (!props && childrenLength !== 0) {
    props = {
      children: void 0
    };
  }

  if (props && defaultProps) {
    for (var propName in defaultProps) {
      if (props[propName] === void 0) {
        props[propName] = defaultProps[propName];
      }
    }
  } else if (!props) {
    props = defaultProps || {};
  }

  if (childrenLength === 1) {
    props.children = children;
  } else if (childrenLength > 1) {
    var childArray = new Array(childrenLength);

    for (var i = 0; i < childrenLength; i++) {
      childArray[i] = arguments[i + 3];
    }

    props.children = childArray;
  }

  return {
    $$typeof: REACT_ELEMENT_TYPE,
    type: type,
    key: key === undefined ? null : "" + key,
    ref: null,
    props: props,
    _owner: null
  };
}

function _objectWithoutProperties(source, excluded) {
  if (source == null) return {};
  var target = {};
  var sourceKeys = Object.keys(source);
  var key, i;

  for (i = 0; i < sourceKeys.length; i++) {
    key = sourceKeys[i];
    if (excluded.indexOf(key) >= 0) continue;
    target[key] = source[key];
  }

  if (Object.getOwnPropertySymbols) {
    var sourceSymbolKeys = Object.getOwnPropertySymbols(source);

    for (i = 0; i < sourceSymbolKeys.length; i++) {
      key = sourceSymbolKeys[i];
      if (excluded.indexOf(key) >= 0) continue;
      if (!Object.prototype.propertyIsEnumerable.call(source, key)) continue;
      target[key] = source[key];
    }
  }

  return target;
}

function _objectSpread(target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = arguments[i] != null ? arguments[i] : {};
    var ownKeys = Object.keys(source);

    if (typeof Object.getOwnPropertySymbols === "function") {
      ownKeys = ownKeys.concat(Object.getOwnPropertySymbols(source).filter(function (sym) {
        return Object.getOwnPropertyDescriptor(source, sym).enumerable;
      }));
    }

    ownKeys.forEach(function (key) {
      _defineProperty(target, key, source[key]);
    });
  }

  return target;
}

function _defineProperty(obj, key, value) {
  if (key in obj) {
    Object.defineProperty(obj, key, {
      value: value,
      enumerable: true,
      configurable: true,
      writable: true
    });
  } else {
    obj[key] = value;
  }

  return obj;
}

var GradientBackground = _styledComponents.default.button.withConfig({
  displayName: "GradientButton__GradientBackground"
})(["position:relative;display:flex;box-sizing:border-box;padding:", "px;border:0;outline:0;background-image:linear-gradient( ", ",", " );cursor:pointer;", ";", ";", ";", ";text-decoration:none;"], function (props) {
  return props.borderWidth;
}, (0, _styledTools.ifProp)(function (_ref) {
  var angle = _ref.angle;
  return angle.includes("deg");
}, (0, _styledTools.prop)("angle"), function (props) {
  return "to ".concat((0, _styledTools.prop)("angle")(props));
}), function (props) {
  return (0, _utils.getLinearGradient)(props.theme, props.gradient);
}, (0, _styledTools.ifProp)("disabled", (0, _styledComponents.css)(["cursor:not-allowed;opacity:0.65;"])), _styledSystem.borderRadius, _styledSystem.color, _styledSystem.fontSize);

GradientBackground.propTypes = _objectSpread({
  angle: _propTypes.default.string.isRequired,
  borderWidth: _propTypes.default.number.isRequired,
  gradient: _propTypes.default.arrayOf(_propTypes.default.string),
  theme: _propTypes.default.string.isRequired
}, _styledSystem.borderRadius.propTypes, _styledSystem.color.propTypes, _styledSystem.fontSize.propTypes);
GradientBackground.defaultProps = {
  color: "#ae3560",
  fontSize: 16
};

var Inner = _styledComponents.default.div.withConfig({
  displayName: "GradientButton__Inner"
})(["width:100%;height:100%;padding:", ";outline:0;", ";", ";", ";"], function (props) {
  return (0, _utils.getPadding)(props.padding);
}, (0, _styledTools.ifProp)({
  disabled: false
}, (0, _styledComponents.css)(["transition:", " ", "s ", " ", "s;&:hover{background:transparent;color:#fff;}"], (0, _styledTools.prop)("transition.property"), (0, _styledTools.prop)("transition.duration"), (0, _styledTools.prop)("transition.timingFunction"), (0, _styledTools.prop)("transition.delay"))), _styledSystem.borderRadius, _styledSystem.color);

Inner.propTypes = _objectSpread({
  padding: _propTypes.default.oneOfType([_propTypes.default.number, _propTypes.default.string, _propTypes.default.array]).isRequired,
  transition: _propTypes.default.objectOf(_propTypes.default.oneOfType([_propTypes.default.number, _propTypes.default.string])).isRequired
}, _styledSystem.borderRadius.propTypes);

var GradientButton = function GradientButton(_ref2) {
  var angle = _ref2.angle,
      _bg = _ref2.background,
      _borderRadius = _ref2.borderRadius,
      borderWidth = _ref2.borderWidth,
      children = _ref2.children,
      disabled = _ref2.disabled,
      gradient = _ref2.gradient,
      padding = _ref2.padding,
      theme = _ref2.theme,
      transition = _ref2.transition,
      props = _objectWithoutProperties(_ref2, ["angle", "background", "borderRadius", "borderWidth", "children", "disabled", "gradient", "padding", "theme", "transition"]);

  return _react.default.createElement(GradientBackground, _extends({
    borderRadius: _borderRadius,
    borderWidth: borderWidth,
    angle: angle,
    disabled: disabled,
    gradient: gradient,
    theme: theme
  }, props), _jsx(Inner, {
    bg: _bg,
    borderRadius: _borderRadius - (borderWidth + 1),
    disabled: disabled,
    padding: padding,
    transition: transition
  }, void 0, children));
};

GradientButton.propTypes = _objectSpread({
  angle: _propTypes.default.string,
  background: _propTypes.default.string,
  borderRadius: _propTypes.default.number,
  borderWidth: _propTypes.default.number,
  children: _propTypes.default.oneOfType([_propTypes.default.node, _propTypes.default.string]).isRequired,
  disabled: _propTypes.default.bool,
  gradient: _propTypes.default.arrayOf(_propTypes.default.string),
  padding: _propTypes.default.oneOfType([_propTypes.default.number, _propTypes.default.string, _propTypes.default.array]),
  theme: _propTypes.default.string,
  transition: _propTypes.default.objectOf(_propTypes.default.oneOfType([_propTypes.default.number, _propTypes.default.string]))
}, _styledSystem.color.propTypes, _styledSystem.fontSize.propTypes);
GradientButton.defaultProps = {
  angle: "right",
  background: "#fff",
  borderRadius: 20,
  borderWidth: 2,
  disabled: false,
  gradient: null,
  padding: 10,
  theme: "Vanusa",
  transition: {
    property: "all",
    duration: 0.2,
    timingFunction: "ease-in-out",
    delay: 0
  }
};
var _default = GradientButton;
exports.default = _default;
export default exports;