import _gradients2 from "./gradients";
var exports = {};
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getPadding = exports.getLinearGradient = void 0;
var _gradients = _gradients2;

function _toArray(arr) {
  return _arrayWithHoles(arr) || _iterableToArray(arr) || _nonIterableRest();
}

function _nonIterableRest() {
  throw new TypeError("Invalid attempt to destructure non-iterable instance");
}

function _iterableToArray(iter) {
  if (Symbol.iterator in Object(iter) || Object.prototype.toString.call(iter) === "[object Arguments]") return Array.from(iter);
}

function _arrayWithHoles(arr) {
  if (Array.isArray(arr)) return arr;
}

var getLinearGradient = function getLinearGradient(theme, cutomizedGradient) {
  var _ref = cutomizedGradient || (0, _gradients.getGradientColors)(theme),
      _ref2 = _toArray(_ref),
      firstColor = _ref2[0],
      elseColors = _ref2.slice(1);

  var str = elseColors.map(function (color, index) {
    return "".concat(color, " ").concat(100 / elseColors.length * (index + 1), "%");
  }).join(", ");
  return "".concat(firstColor, " 0%, ").concat(str);
};

exports.getLinearGradient = getLinearGradient;

var getPadding = function getPadding(prop) {
  if (typeof prop === "string" || typeof prop === "number") {
    return "".concat(prop, "px");
  } else if (Array.isArray(prop)) {
    if (prop.length > 4) {
      console.warn("Warning: padding property should have maximum four values.");
    }

    return prop.slice(0, 4).map(function (p) {
      return "".concat(p, "px");
    }).join(" ");
  }

  console.error("Warning: Type of padding property should be either \"String\", \"Number\" or \"Array\"");
};

exports.getPadding = getPadding;
export default exports;